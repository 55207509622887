.slider {
  @apply relative w-full;
}

.slider__track,
.slider__range,
.slider__left-value,
.slider__right-value,
.slider__left-text,
.slider__right-text {
  @apply absolute;
}

.slider__track,
.slider__range {
  @apply bottom-4 h-1.5 rounded;
}

.slider__track {
  @apply z-1 w-full bg-gray-300;
}

.slider__range {
  @apply z-2 bg-black;
}

.slider__left-text,
.slider__right-text {
  @apply bottom-12 text-1xs leading-4 text-gray-300;
}

.slider__left-text {
  @apply left-0;
}

.slider__right-text {
  @apply right-0;
}

.slider__left-value,
.slider__right-value {
  @apply bottom-8 text-xs leading-4 text-gray-500;
}

.slider__left-value {
  @apply left-0;
}

.slider__right-value {
  @apply right-0;
}

.bg-slider {
  @apply rounded-md bg-white font-GTWalsheimPro text-base font-normal not-italic text-gray-500 shadow-md;
}

.bg-slider-range {
  @apply rounded-lg bg-blue-400;
}

/* Removing the default appearance */
input[type='range'].thumb,
input[type='range'].thumb::-webkit-slider-thumb {
  -webkit-appearance: none;
  -webkit-tap-highlight-color: transparent;
}

input[type='range'].thumb {
  @apply pointer-events-none absolute bottom-6 h-0 w-full shadow outline-none;
}

input[type='range'].thumb--zindex-3 {
  @apply z-3;
}

input[type='range'].thumb--zindex-4 {
  @apply z-4;
}

input[type='range'].thumb--zindex-5 {
  @apply z-5;
}

/* For Chrome browsers */
input[type='range'].thumb::-webkit-slider-thumb {
  @apply relative mt-2 h-5 w-5 cursor-pointer rounded-full border-none bg-white shadow pointer-events-all;
}

/* For Firefox browsers */
input[type='range'].thumb::-moz-range-thumb {
  @apply relative mt-2 h-5 w-5 cursor-pointer rounded-full border-none bg-white shadow pointer-events-all;
}
