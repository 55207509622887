.rnc__notification-content {
  @apply px-[20px] py-[16px] !important;
}

.rnc__notification-item {
  @apply rounded-bl-none rounded-br-lg rounded-tl-none rounded-tr-lg border-l-[5px] shadow-none !important;
}

.rnc__notification-title {
  @apply my-0 text-[16px] font-medium  !important;
}

.rnc__notification-item--success {
  @apply border-[#157046] bg-[#EEF6F3] !important;
}

.rnc__notification-item--success .rnc__notification-title {
  @apply text-[#157046] !important;
}

.rnc__notification-item--danger {
  @apply border-[#A92836] bg-[#FCEEEF] !important;
}

.rnc__notification-item--danger .rnc__notification-title {
  @apply text-[#A92836] !important;
}

.rnc__notification-item--warning {
  @apply border-[#8B6904] bg-[#FFFBF0] !important;
}

.rnc__notification-item--warning .rnc__notification-title {
  @apply text-[#8B6904] !important;
}
