.transition-fade-ease-enter {
  @apply opacity-0;
}

.transition-fade-ease-enter-active {
  @apply opacity-100 transition-all duration-300 ease-out;
}

.transition-fade-ease-exit {
  @apply opacity-100 transition-all duration-300 ease-in-out;
}

.transition-fade-ease-exit-active {
  @apply opacity-0 transition-all duration-300 ease-out;
}

.transition-fade-ease-exit-done {
  @apply opacity-0;
}
