@tailwind base;
@tailwind components;
@tailwind utilities;

@import 'pure-react-carousel/dist/react-carousel.es.css';
@import 'react-notifications-component/dist/theme.css';

@layer components {
  @import 'styles/transitions/index';
  @import 'styles/components/index';
}

@layer utilities {
  @import 'styles/utilities.css';
}

.lot-view-control-icon {
  @apply inline-flex h-8 w-8 items-center justify-center rounded bg-[#747474] text-white;
}

.lot-view-control-text {
  @apply text-sm font-bold uppercase text-white drop-shadow-[3px_3px_4px_rgb(0_0_0_/_0.5)] transition-opacity duration-300 ease-in-out;
}
