.carousel.custom .carousel__slider {
  @apply h-full;
}

.carousel__slide-focus-ring {
  @apply hidden;
}

.carousel.building .carousel__slider {
  @apply h-full;
}

.carousel.building .carousel__slider .carousel__slider-tray-wrapper {
  @apply h-full;
}

.carousel.building
  .carousel__slider
  .carousel__slider-tray-wrapper
  .carousel__slider-tray {
  @apply h-full;
}

.carousel.building .carousel__slider-tray .carousel__slide {
  @apply pb-0;
}
