.transition-toTop-enter {
  @apply translate-y-full transform-gpu opacity-0 transition-all;
}

.transition-toTop-enter-active {
  @apply translate-y-0 transform-gpu opacity-100 transition-all duration-500 ease-in-out;
}

.transition-toTop-exit {
  @apply translate-y-0 transform-gpu opacity-100 transition-all;
}

.transition-toTop-exit-active {
  @apply translate-y-full transform-gpu opacity-0 transition-all duration-500 ease-in-out;
}
