form.basic-white-form {
  @apply mb-4 w-auto min-w-300px rounded bg-white px-8 pb-8 pt-6 font-GTWalsheimPro shadow-md;
}

input[type='text'].input-gray,
input[type='email'].input-gray,
input[type='password'].input-gray {
  @apply w-full appearance-none rounded border bg-gray-100 px-3 py-2 leading-tight text-gray-800;
}

input[type='text'].input-gray:focus,
input[type='email'].input-gray:focus,
input[type='password'].input-gray:focus {
  --tw-ring-color: #71717a;
  border-color: transparent;
}

input.input-gray-error:focus {
  --tw-ring-color: rgba(239, 68, 68, var(--tw-border-opacity));
  border-color: transparent;
}

input.input-gray-error {
  @apply mb-3 w-full appearance-none rounded border border-red-500 bg-gray-100 px-3 py-2 leading-tight text-gray-800;
}

label.input-label {
  @apply mb-2 block text-sm text-gray-700;
}

label.badge {
  @apply font-GTWalsheimPro;
}

label.badge > span {
  @apply ml-3 cursor-pointer rounded-full border border-black bg-white px-1 py-1 text-lg text-black;
}

label.badge > input[type='radio'] {
  @apply hidden;
}

label.badge > input[type='radio']:checked + span {
  @apply rounded-full border border-black bg-black px-1 py-1 text-lg text-white;
}

@screen md {
  label.badge > span {
    @apply ml-3 cursor-pointer rounded-full border border-black bg-white px-2 py-1 text-xl text-black;
  }

  label.badge > input[type='radio']:checked + span {
    @apply rounded-full border border-black bg-black px-2 py-1 text-xl text-white;
  }
}

@screen lg {
  label.badge > span {
    @apply ml-3 cursor-pointer rounded-full border border-black bg-white px-3 py-1 text-2xl text-black;
  }

  label.badge > input[type='radio']:checked + span {
    @apply rounded-full border border-black bg-black px-3 py-1 text-2xl text-white;
  }
}

input[type='checkbox'].checkbox-gray {
  @apply rounded border-2 border-gray-300 p-3;
}

input[type='checkbox'].checkbox-gray:checked {
  @apply border-2 border-gray-300 bg-white text-black svg-tick-black;
}

input[type='checkbox'].checkbox-gray:focus {
  @apply ring-0;
}

input[type='checkbox'].checkbox-gray + span {
  @apply text-gray-300;
}

input[type='checkbox'].checkbox-gray:checked + span {
  @apply text-black;
}

label.checkbox-white {
  @apply inline-block text-gray-500 ipad-mini-land:flex;
}

label.checkbox-white input {
  @apply h-10 w-10 rounded border-white bg-transparent text-transparent;
}

label.checkbox-white span {
  @apply flex-1 text-right text-sm leading-9 text-white ipad-mini-land:text-xl;
}

label.checkbox-white input:hover,
label.checkbox-white input:checked:hover {
  @apply border border-white bg-accentClassic;
}

label.checkbox-white input:checked,
label.checkbox-white input:checked:focus {
  @apply border border-white;
}

label.checkbox-white input:focus {
  @apply border border-white ring-0 ring-offset-0 ring-offset-transparent;
}
