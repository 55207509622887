.transition-flip-enter {
  @apply absolute inset-0 opacity-0 transfrom-flip-rotate;
}

.transition-flip-enter-active {
  @apply absolute inset-0 transform-gpu opacity-100 transition-all duration-500 ease-in-out transfrom-flip-neutral;
}

.transition-flip-exit {
  @apply absolute inset-0 opacity-100 transfrom-flip-rotate;
}

.transition-flip-exit-active {
  @apply absolute inset-0 transform-gpu opacity-0 transition-all duration-500 ease-in-out transfrom-flip-neutral;
}
