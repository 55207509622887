.transition-zoom-out-qr-enter {
  @apply !w-[250px] opacity-100;
}

.transition-zoom-out-qr-enter-active {
  @apply !w-[100px] !p-3 opacity-50 transition-all duration-300 ease-in;
}

.transition-zoom-out-qr-enter-done {
  @apply !w-[100px] !p-3 opacity-50;
}

.transition-zoom-out-qr-exit {
  @apply !w-[100px] !p-3 opacity-50;
}

.transition-zoom-out-qr-exit-active {
  @apply !w-[250px] opacity-100 transition-all duration-300 ease-in;
}

.transition-zoom-out-qr-exit-done {
  @apply !w-[250px] opacity-100;
}
