.transition-slide-enter .transition-slide-gallerySlide {
  @apply translate-x-40 transition-all duration-300 ease-in-out;
}

.transition-slide-enter-active .transition-slide-gallerySlide {
  @apply -translate-x-0 transform-gpu transition-all duration-300 ease-in-out;
}

.transition-slide-enter-done .transition-slide-gallerySlide {
  @apply -translate-x-0 transform-gpu transition-all duration-300 ease-in-out;
}
