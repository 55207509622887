.snaploader-v3d-disclaimer {
  @apply hidden !important;
}

.snaploader-v3d-plugin-menu {
  @apply hidden !important;
}

.snaploader-v3d-interact-overlay-content {
  @apply hidden !important;
}
