@import url('https://d1bw6x5263wwbc.cloudfront.net/css/pannellum.css');

.pnlm-load-box {
  @apply hidden !important;
}

.pnlm-hotspot-base.pnlm-hotspot {
  background-image: url(svg/dot_circle_icon.svg) !important;
}

#panorama-builder .pnlm-hotspot-base.pnlm-hotspot.pnlm-tooltip span {
  visibility: visible !important;
}

#panorama-builder .pnlm-tooltip span:after {
  content: '' !important;
  position: absolute !important;
  width: 0 !important;
  height: 0 !important;
  border-width: 10px !important;
  border-style: solid !important;
  border-color: rgba(0, 0, 0, 0.7) transparent transparent transparent !important;
  bottom: -20px !important;
  left: -10px !important;
  margin: 0 50% !important;
}

div.pnlm-tooltip span {
  @apply w-auto !important;
}
