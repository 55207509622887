.transition-fade-ease-slide-enter {
  @apply -translate-x-20 opacity-0;
}

.transition-fade-ease-slide-enter-active {
  @apply translate-x-0 transform-gpu opacity-100 transition-all duration-300 ease-out;
}

.transition-fade-ease-slide-exit {
  @apply opacity-100 transition-all duration-300 ease-in-out;
}

.transition-fade-ease-slide-exit-active {
  @apply opacity-0 transition-all duration-300 ease-out;
}

.transition-fade-ease-slide-exit-done {
  @apply opacity-0;
}
