.background-cover {
  @apply h-screen w-full overflow-hidden bg-cover bg-center object-cover;
}

.background-contain {
  @apply h-screen w-full overflow-hidden bg-contain bg-center bg-no-repeat object-contain;
}

.shimmer-container {
  @apply isolate space-y-5 overflow-hidden rounded-2xl
  bg-white/5 p-4 shadow-xl shadow-black/5
  before:absolute
  before:inset-0
  before:-translate-x-full
  before:animate-[shimmer_2s_infinite]
  before:bg-gradient-to-r
  before:from-transparent before:via-gray-50/40
  before:via-20% before:to-transparent
  before:to-50%;
}

.skeleton {
  @apply h-[100px] w-[30%] rounded-lg;
  background-color: #ffffff50;
  background-image: radial-gradient(10% 50% at 50%, #ffffffcc, #ffffff00);
  animation: gradient 2s cubic-bezier(0, 0.4, 1, 0.6) infinite reverse both;
  background-size: 400% 400%;
  background-attachment: fixed;
  mix-blend-mode: overlay;
}

.skeleton.pill {
  border-radius: 100px;
  width: 15%;
  height: 45px;
  position: relative;
}

.skeleton.pill::before {
  content: '';
  width: 4px;
  height: 160px;
  background-color: inherit;
  position: absolute;
  top: 45px;
  left: 50%;
  transform: translateX(-50%);
}

.skeleton.pill::after {
  content: '';
  width: 16px;
  height: 16px;
  background-color: inherit;
  position: absolute;
  top: 205px;
  left: 50%;
  transform: translateX(-50%);
  border-radius: 100px;
}

@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  100% {
    background-position: 100% 50%;
  }
}
